import { Component, AfterViewInit, HostListener, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Platform, AlertController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { from, map, Observable, Subject, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { version as appVersion } from 'src/app/version';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { UserService } from './services/user/user.service';
import { AuthAsService } from './services/auth-as/auth-as.service';
import { TokenService } from './services/token/token.service';
import { PushService } from './services/push/push.service';
import { UserType } from './services/roles';
import { AbraAuthService } from './services/abraAuth/abra-auth.service';

import { AppConfig } from './variables';
import { initSentry } from './sentry';
import { LiveActivityPluginService } from './services/live-activity/live-activity-plugin.service';
import { ChargingStatus } from './services/live-activity/live-activity.interface';

register();

@Component({
  selector: 'sc-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterViewInit {
  appConfig: AppConfig = new AppConfig();
  largeScreen: boolean = false;
  hideAppBar: boolean = false;
  ready: boolean = false;
  loggedIn$: Observable<boolean>;
  loggedInAs: any;
  version: string = appVersion;
  tapLog: any = {
    tapped: 0,
    timer: null
  };
  isIframe: boolean = false; //Shows only iframe content if true. No navigation
  resume: Subject<any>;
  ionAppStyle: any = {};
  UserType = UserType;

  constructor(
    public platform: Platform,
    public tokenService: TokenService,
    private translate: TranslateService,
    private storage: Storage,
    private _http: HttpInterceptorService,
    private userService: UserService,
    private alertCtrl: AlertController,
    private authAsService: AuthAsService,
    private location: Location,
    private pushService: PushService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    private abraAuthService: AbraAuthService,
    private zone: NgZone,
    private liveActivityPlugin: LiveActivityPluginService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
      if (val.url.includes('?oauth=')) {
        this.location.replaceState('stations');
        this.abraAuthService.fetchUserDetails().then((userDetails) => {
          if (userDetails && userDetails?.accessToken) {
            this.userService.loginAbraUser(userDetails).then((customer) => {});
          }
        });
      }
    });

    this.loggedIn$ = this.userService.loggedIn$.pipe(
      switchMap((loggedIn) => from(this.userService.isAdhocUser()).pipe(map((isAdhoc) => loggedIn && !isAdhoc)))
    );

    this.initializeApp();

    // add-payment is the old way. Remove after a while
    if (window.location.href.includes('/frame/') || window.location.href.includes('/add-payment/')) {
      this.isIframe = true;
    }
  }
  ngAfterViewInit() {
    this.mobileOrWeb();

    this.checkAuthAs();
    this.checkLoggedInAs();
  }

  initializeApp() {
    this.updateAppBar();
    this.isIE();
    this.setLanguage();
    this.title.setTitle(this.appConfig.title);
    this.setTheme();
    this.subscribeDeepLinks();
    initSentry(this.appConfig.title, this.version);

    if (this.platform.is('capacitor')) {
      SplashScreen.hide();
    }

    this.platform.ready().then(() => {
      this.pushService.init();
    });
  }
  setTheme() {
    this.meta.updateTag({ content: this.appConfig.colors.primary }, 'name=theme-color');
  }
  setLanguage() {
    if (window.location.href.includes('/frame/') || window.location.href.includes('/add-payment/')) return; //Don't set language for iframes, in this case we send language to the frame

    //Check if the user has stored a language in his preferences. In that case, we use it
    this.userService.getUserID().then((userID) => {
      if (userID) {
        this._http.get('UserPreferences/GetUserPreferences/' + userID).subscribe((preferences) => {
          if (preferences && preferences.language) {
            this.updateLanguage(preferences.language);
          }
        });
      }
    });
  }
  private updateLanguage(language) {
    if (!new RegExp(this.appConfig.availableLangs.join('|')).test(language)) {
      //Checks if browserlanguage is supported, fallback to english
      language = this.appConfig.defaultLang;
    }

    this.translate.use(language); //Finds the correct language
    this.storage.set('lang', language); //Stores the language
  }

  updateAppBar() {
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        const hideAppBar = route.data.hideAppBar || false;
        this.hideAppBar = hideAppBar;
      }
    });
  }

  isIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      if (parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) <= 9) {
        this.alertCtrl
          .create({
            header: this.translate.instant('ERROR.OUTDATED_BROWSER'),
            message: this.translate.instant('ERROR.OUTDATED_BROWSER_MESSAGE'),
            buttons: [
              {
                text: this.translate.instant('ERROR.FIND_BROWSER'),
                handler: () => {
                  window.open('http://outdatedbrowser.com', '_self');
                }
              }
            ]
          })
          .then((alert) => {
            alert.present();
          });
      }
    }
  }

  openAuthAs() {
    this.alertCtrl
      .create({
        header: 'Logged in as',
        message: this.loggedInAs.Email,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Logout',
            cssClass: 'delete-button',
            handler: () => {
              this.logoutAs();
            }
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });
  }
  logoutAs() {
    this.authAsService.logout();
  }
  checkAuthAs() {
    let path = this.location.path();
    let email = path.split('?authAs=')[1];

    if (email) {
      this.authAsService.authAs(email);
    }
  }
  checkLoggedInAs() {
    this.storage.get('loggedInUser').then((res) => {
      if (res) {
        this.userService.getUser().then((user) => {
          this.loggedInAs = user;
        });
      }
    });
  }
  showLog() {
    //Showing log as alert if img in menu is tapped 7 times with less than 3 second between each tap

    this.tapLog.tapped++;
    clearTimeout(this.tapLog.timer);
    this.tapLog.timer = setTimeout(() => (this.tapLog.tapped = 0), 3000);

    if (this.tapLog.tapped === 7) {
      this.tapLog.tapped = 0;
      this.storage.get('errorLog').then((message) => {
        alert(message);
      });
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event?.target?.innerWidth) {
      if (event.target.innerWidth > 991) {
        this.largeScreen = true;
      } else {
        this.largeScreen = false;
      }
    }
  }

  private subscribeDeepLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = event.url.replace('/#/', '/');
        const { pathname } = new URL(url);

        if (pathname && pathname !== window.location.pathname) {
          this.router.navigateByUrl(pathname);
        }
      });
    });
  }

  private mobileOrWeb() {
    this.platform.ready().then(() => {
      if (this.platform.width() > 991) {
        this.largeScreen = true;
      }
      this.checkWhereToGo();

      this.ready = true;
    });
  }

  private checkWhereToGo() {
    let path = this.location.path();
    let query = path.split('?env=')[1];
    if (query) {
      console.log('Changing environment to ' + query);
      console.log('Logging out...');
      this.userService.logout().then((res) => {
        console.log('Logged out');
      });
      this.storage.set('environment', query).finally(() => {
        console.log('Reloading...');
        this.location.replaceState('stations');
        location.reload();
      });
    }
  }
}

(window as any).global = window;
declare global {
  interface Document {
    documentMode?: any;
  }
}
