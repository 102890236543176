import { Inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import OneSignal, { LogLevel } from 'onesignal-cordova-plugin';

import { APP_CONFIG, AppConfig } from '@smartcharge/shared/core/config';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

export type PushState = 'unknown' | 'accepted' | 'rejected';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  get state(): PushState {
    return this._state;
  }

  private initialized = false;
  private _state: PushState = 'unknown';

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly storage: Storage
  ) {}

  init(): void {
    if (this.initialized || !this.isSupported()) {
      return;
    }

    OneSignal.initialize(this.appConfig.pushAppID);
    OneSignal.Debug.setLogLevel(LogLevel.Verbose);

    App.addListener('resume', () => {
      OneSignal.Notifications.clearAll();
    });

    OneSignal.Notifications.clearAll();

    this.initialized = true;
  }

  async isRegistered(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      return false;
    }

    return OneSignal.Notifications.getPermissionAsync();
  }

  isSupported(): boolean {
    return Capacitor.isNativePlatform() && !!this.appConfig.pushAppID;
  }

  login(): void {
    // NOTE: Not supported yet
  }

  logout(): void {
    // NOTE: Not supported yet
  }

  async register(): Promise<string> {
    if (!this.isSupported()) {
      return;
    }

    if (!this.initialized) {
      this.init();
    }

    const userIDPrefix =
      this.appConfig.appID === 'eco.current' ? 'CURRENTECO_' : '';

    if (
      (await OneSignal.Notifications.getPermissionAsync()) ||
      (await OneSignal.Notifications.requestPermission(true))
    ) {
      OneSignal.setConsentGiven(true);

      const token = await OneSignal.User.pushSubscription.getIdAsync();

      if (typeof token === 'string') {
        const userID = userIDPrefix + token;
        await this.storage.set('pushToken', userID);

        this._state = 'accepted';

        return userID;
      }
    }

    this._state = 'rejected';

    return;
  }
}
